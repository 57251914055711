/* eslint-disable react/no-unescaped-entities */
import { Trans } from "@lingui/macro";
import { FC, FormEvent, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import FamilyStatusForm from "../../../../components/FamilyStatusForm";
import { ProfileStatus } from "../../../../components/FamilyStatusForm/types";
import { useUpdateFamilyStatus } from "../../../../components/FamilyStatusForm/useUpdateFamilyStatus";
import { colors } from "../../../../constants/colors";
import { Title } from "../../../../lib";
import Button from "../../../../lib/Button";
import Icon from "../../../../lib/SharedIcon";
import mediaQuery from "../../../../utils/mediaQuery";
import Layout, { Content, Header } from "../Layout";

const Top = styled.div`
  padding-top: 20px;
  text-align: center;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      text-align: start;
      padding: 0;
    `,
  )}
`;

const StyledTitle = styled(Title)`
  ${mediaQuery(
    "phone",
    css`
      font-size: 24px;
      line-height: 29px;
    `,
  )}
`;

const Description = styled.p`
  font-size: 15px;
  line-height: 24px;
  margin: 0;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-bottom: 30px;
    `,
  )}
`;

const Footnote = styled.p`
  color: ${colors.brownBlack};
  font-size: 12px;
  line-height: 16px;

  align-items: center;
  display: flex;
  gap: 6px;
  padding: 0 20px;
  margin-top: 10px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 0;
      margin-top: 20px;
    `,
  )}
`;

const ButtonContainer = styled.div`
  margin-top: 40px;

  ${mediaQuery(
    "phone",
    css`
      margin-top: 0;
    `,
  )}
`;

const Form = styled.form`
  padding: 0;

  ${mediaQuery(
    "phone",
    css`
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    `,
  )}
`;

const StyledLayout = styled(Layout)`
  ${Content} {
    max-width: 800px;
  }

  ${StyledTitle} {
    margin-bottom: 9px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-bottom: 20px;
      `,
    )}
  }

  ${mediaQuery(
    "phone",
    css`
      padding-top: 0;
      height: 100%;
      grid-template-areas: "content";

      ${Header} {
        display: none;
      }
    `,
  )}
`;

export interface StatusScreenProps {
  availableStatuses: readonly ProfileStatus[];
  userStatus: ProfileStatus | null;
}

const StatusScreen: FC<StatusScreenProps> = ({
  availableStatuses,
  userStatus,
}) => {
  const [status, setStatus] = useState<ProfileStatus | null>(null);
  const { mutate: updateStatus, isLoading } = useUpdateFamilyStatus();

  const handleClick = useCallback(
    (selectedStatus: ProfileStatus) => {
      if (isLoading) return;
      setStatus(selectedStatus);
    },
    [isLoading],
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (!status) return;
      updateStatus(status);
    },
    [updateStatus, status],
  );

  return (
    <StyledLayout title="">
      <Form onSubmit={handleSubmit}>
        <Top>
          <StyledTitle level="h1">
            {userStatus ? (
              <Trans>Update your current status</Trans>
            ) : (
              <Trans>Choose your current status</Trans>
            )}
          </StyledTitle>

          <Description>
            <Trans>
              To personalize your experience with curated care,
              experts, and resources.
            </Trans>
          </Description>
        </Top>

        <FamilyStatusForm
          availableStatuses={availableStatuses}
          onChange={handleClick}
          status={status}
        />
        <ButtonContainer>
          <Button disabled={!status} kind="filledCoral" type="submit">
            <Trans>Continue</Trans>
          </Button>

          <Footnote>
            <Icon type="lock" />

            <Trans>
              This information will always stay private and you can
              always change your status later
            </Trans>
          </Footnote>
        </ButtonContainer>
      </Form>
    </StyledLayout>
  );
};

export default StatusScreen;
