import { captureException } from "@sentry/react";
import { useCallback, useState } from "react";
import { v4 } from "uuid";
import type { SessionUser } from "../authentication/hooks/useAuthSession";

export function useSyncSession() {
  return useCallback(async (user: SessionUser) => {
    try {
      if (!user.attributes.sendbird_credentials.access_token) {
        throw new Error("No SendBird access token"); // normally we should not be here as we have a guard component above.
      }
      if (window.ReactNativeWebView) {
        const payload: {
          ok: true;
          user_id: number;
          preferred_language: string; // common locale like "en"
          sendbird: {
            app_id: string;
            access_token: string;
            user_id: string;
            channel_url: string;
          };
          care_navigator: {
            userpic_url: string | null; // 80х80 // maybe a little bigger for retina
          };
        } = {
          care_navigator: {
            userpic_url:
              user.careNavigator.attributes.image_set?.w128 || null,
          },
          ok: true,
          preferred_language: user.attributes.preferred_language,
          sendbird: {
            access_token:
              user.attributes.sendbird_credentials.access_token,
            app_id: user.attributes.sendbird_credentials.app_id,
            channel_url:
              user.attributes.sendbird_credentials.channel_url,
            user_id: user.attributes.sendbird_credentials.user_id,
          },
          user_id: Number(user.id),
        };

        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "set_session",
            payload,
          }),
        );
      }
    } catch (e) {
      captureException(e, {
        extra: {
          source: "Failed to sync session to ReactNativeWebView",
        },
      });
    }
  }, []);
}

/**
 * Using this hook you can ask ReactNative for a question
 */
export function usePostMessageQuestion() {
  const [messageId] = useState(() => v4());

  return useCallback(
    <T,>(action: string, cb: (result: T | null) => void) => {
      if (!window.ReactNativeWebView) {
        throw new Error("Not in ReactNativeWebView");
      }

      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action,
          messageId,
        }),
      );

      window.ReactNativeWebViewAnswerHandlers =
        window.ReactNativeWebViewAnswerHandlers || {};

      window.ReactNativeWebViewAnswerHandlers[messageId] = (
        result: string,
      ) => {
        if (window.ReactNativeWebViewAnswerHandlers) {
          delete window.ReactNativeWebViewAnswerHandlers[messageId];
        }

        let result2: T | null = null;
        try {
          result2 = JSON.parse(result) as T;
        } catch (e) {
          captureException(e, {
            extra: {
              source:
                "Failed to parse result in ReactNativeWebViewAnswerHandlers",
            },
          });
        }
        cb(result2);
      };
    },
    [messageId],
  );
}

/**
 * ReactNative needs to know when the locale changes
 */
export function sendLocaleToRN(localeToSend: string) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "change_locale",
        locale: localeToSend,
      }),
    );
  }
}

export function sendPlaidTokenToRN(token: string) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "plaid_token",
        token,
      }),
    );
  }
}

/**
 * We need to force ReactNative to refresh its config to respect the `new_ux` cookie.
 */
export function refreshRNConfig() {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "refresh_frontend_config" }),
    );
  }
}
